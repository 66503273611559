import React, { FC } from 'react'
import { LayoutPage } from '../layouts/LayoutPage';

const Contact: FC = () => (
    <LayoutPage>
        <>Contact</>
    </LayoutPage>
);

export default Contact;
